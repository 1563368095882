






















import Vue from "vue";

export default Vue.extend({
  data: () => ({
    page_groups: [
      {
        title: "WEBセンターの設定",
        items: [
          [
            "mdi-newspaper-variant-multiple-outline",
            "全体のお知らせ",
            "/setting/information",
          ],
          ["mdi-calendar-month", "お届け日計算カレンダー", "/setting/calendar"],
        ],
      },
      {
        title: "メッセージカードの設定",
        items: [
          [
            "mdi-cards-outline",
            "カードテンプレート",
            "/setting/message_card_template",
          ],
        ],
      },
      {
        title: "その他の設定",
        items: [["mdi-store", "受け取り可能店舗", "/setting/pickup_store"]],
      },
    ],
  }),
});
